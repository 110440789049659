(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name sliceApp.factory:App
   *
   * @description
   *
   */
  angular
    .module('sliceApp')
    .factory('App', App);

  function App($http, consts) {
    var AppBase = {};

    AppBase.getFranchiseGraphics = function() {
      return $http({
        method: "GET",
        url: consts.serverUrl + "MetaData/GetFranchiseGraphics",
        params: {
          franchiseId: consts.franchiseId
        }
      });
    };

    AppBase.verifyToken = function(token) {
      return $http({
        method: "POST",
        url: consts.serverUrl + "Account/VerifyToken",
        data: {
          token: token,
          franchiseId: consts.franchiseId
        }
      });
    }

    AppBase.updateUserDetails = function(user) {
      return $http({
        method: "POST",
        url: consts.serverUrl + "Account/UpdateUserDetails",
        data: {
          user: user
        }
      });
    }

    AppBase.getMenu = function() {
      return $http({
        method: "GET",
        url: consts.serverUrl + "/Menu/GetMenu",
        params: {
          franchiseId: consts.franchiseId
        }
      });
    };

    AppBase.getFranchise = function() {
      return $http({
        method: "GET",
        url: consts.serverUrl + "/MetaData/GetFranchiseWithBranches",
        params: {
          franchiseId: consts.franchiseId
        }
      });
    }

    AppBase.getCombos = function() {
      return $http({
        method: "GET",
        url: consts.serverUrl + "/MetaData/GetCombos",
        params: {
          franchiseId: consts.franchiseId
        }
      });
    }

    return AppBase;
  }
}());
